<template>
  <Menu
    ref="menuEl"
    as="div"
    class="relative inline-block text-left"
    v-slot="{ close: closeMenu, open: menuOpened }: { close: () => void; open: boolean }"
  >
    <div @click="(e) => handleMenuOpened(e, menuOpened)">
      <MenuButton class="inline-flex w-full gap-x-1.5 text-sm font-semibold text-inherit">
        <slot />
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-inherit" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="bg-white text-primary lg:absolute lg:right-0 lg:z-30 lg:mt-2 lg:w-56 lg:origin-top-right lg:rounded-md lg:shadow-lg lg:ring-1 lg:ring-black lg:ring-opacity-5 lg:focus:outline-none"
      >
        <template v-for="(item, index) in menuItems" :key="index">
          <div v-if="item === 'break-start'" class="pt-1" />
          <div v-else-if="item === 'break-end'" class="pb-1 mb-1 border-b border-highlight-100" />
          <MenuItem v-else>
            <LinkButton
              :href="item.url"
              class="block py-2 hover:text-highlight text-left lg:px-4"
              target="_self"
              @click.prevent="
                (e) => {
                  if (!route.fullPath.endsWith(item.url)) handleSubMenuClick(e, item, closeMenu)
                }
              "
            >
              {{ item.name }}
            </LinkButton>
          </MenuItem>
        </template>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems, provideUseId } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { onClickOutside } from '@vueuse/core'
import type { MenuItem as MenuItemType } from '~/types/components/menu-item'
import type { Properties as CampgroundEvents } from '~/types/campground-segment-types'

type MenuItemTypeExtended = Omit<CampgroundEvents['Menu Option Clicked'], 'cta' | 'pageSource' | 'item_id' | 'menu_id'>
type MenuItemBlock = 'break-start' | 'break-end'

const props = defineProps<{
  name: string
  items: MenuItemType[] | MenuItemType[][]
  buttonCssClasses: object | string
}>()

const route = useRoute()
const bodyScroll = useBodyScroll()

const handleSubMenuClick = async (e: Event, item: MenuItemTypeExtended, callback?: () => void) => {
  if (callback != null) callback()

  segmentTrack('Menu Option Clicked', {
    ...item,
    menu_id: props.name,
    item_id: item.name
  })
  bodyScroll.value = true

  await navigateTo(item.url)
}

const handleMenuOpened = (e: Event, opened: boolean) => {
  menuOpened.value = opened

  if (menuOpened.value) {
    segmentTrack('Menu Opened', {
      menu_id: props.name,
      items: menuItems.value
        .filter((x) => typeof x !== 'string')
        .map((item) => item as MenuItemTypeExtended)
        .map((item) => {
          return {
            ...item,
            item_id: item.name
          }
        })
    })
  } else {
    segmentTrack('Menu Closed', {
      menu_id: props.name
    })
  }

  bodyScroll.value = !menuOpened.value
}

const menuItems = computed<(MenuItemTypeExtended | MenuItemBlock)[]>(() =>
  props.items.reduce(
    (arr, item) => {
      let index = 0
      if (Array.isArray(item)) {
        arr.push('break-start')
        item.forEach((subitem) => {
          arr.push({
            name: subitem.name,
            url: subitem.href,
            position: ++index
          })
        })
        arr.push('break-end')
      } else {
        arr.push({
          name: item.name,
          url: item.href,
          position: ++index
        })
      }
      return arr
    },
    [] as (MenuItemTypeExtended | MenuItemBlock)[]
  )
)

const menuEl = ref<InstanceType<typeof Menu>>()
const menuOpened = ref(false)

onMounted(() =>
  onClickOutside(menuEl.value?.$el, () => {
    if (!bodyScroll.value) {
      bodyScroll.value = true
    }
    if (menuOpened.value) {
      menuOpened.value = false
      segmentTrack('Menu Closed', {
        menu_id: props.name
      })
    }
  })
)

provideUseId(() => useId())
</script>
