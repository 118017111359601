export default function ouibounce(options: { htmlElement?: HTMLElement; customConfig?: OuiBounceConfig }) {
    'use strict'
  
    const config = typeof options.customConfig !== 'undefined' ? options.customConfig : {},
      aggressive = typeof config.aggressive !== 'undefined' ? config.aggressive : false,
      sensitivity = setDefault(config.sensitivity, 20),
      timer = setDefault(config.timer, 1000),
      delay = setDefault(config.delay, 0),
      callback = typeof config.callback !== 'undefined' ? config.callback : function () {},
      _html = document.documentElement
  
    let _delayTimer: string | number | NodeJS.Timeout | null | undefined = null
    let cookieExpire = setDefaultCookieExpire(config.cookieExpire) || ''
    let sitewide = typeof config.sitewide !== 'undefined' && config.sitewide === true ? ';path=/' : ''
    let cookieDomain = typeof config.cookieDomain !== 'undefined' ? ';domain=' + config.cookieDomain : ''
    let cookieName = typeof config.cookieName !== 'undefined' ? config.cookieName : 'viewedOuibounceModal'
  
    function setDefault(_property: any, _default: number) {
      return typeof _property === 'undefined' ? _default : _property
    }
  
    function setDefaultCookieExpire(expirationDays?: number) {
      if (typeof expirationDays === 'undefined') {
        return
      }
      // transform days to milliseconds
      const ms = expirationDays * 24 * 60 * 60 * 1000
  
      const date = new Date()
      date.setTime(date.getTime() + ms)
  
      return '; expires=' + date.toUTCString()
    }
  
    setTimeout(attachOuiBounce, timer)
    function attachOuiBounce() {
      if (isDisabled()) {
        return
      }
  
      _html.addEventListener('mouseleave', handleMouseleave)
      _html.addEventListener('mouseenter', handleMouseenter)
      _html.addEventListener('keydown', handleKeydown)
    }
  
    function handleMouseleave(e: MouseEvent) {
      if (e.clientY > sensitivity) {
        return
      }
  
      _delayTimer = setTimeout(fire, delay)
    }
  
    function handleMouseenter() {
      if (_delayTimer) {
        clearTimeout(_delayTimer)
        _delayTimer = null
      }
    }
  
    let disableKeydown = false
    function handleKeydown(e: KeyboardEvent) {
      if (disableKeydown) {
        return
      } else if (!e.metaKey || e.key !== 'KeyL') {
        return
      }
  
      disableKeydown = true
      _delayTimer = setTimeout(fire, delay)
    }
  
    function checkCookieValue(cookieName: string, value: string) {
      return parseCookies()[cookieName] === value
    }
  
    function parseCookies() {
      // cookies are separated by '; '
      const cookies = document.cookie.split('; ')
    
      const ret: { [k: string]: any } = {}
      for (let i = cookies.length - 1; i >= 0; i--) {
        const el = cookies[i]?.split('=')
        if (el && el.length >= 2) {
            ret[el[0]] = el[1]
        }
      }
      return ret
    }
  
    function isDisabled() {
      return checkCookieValue(cookieName, 'true') && !aggressive
    }
  
    // You can use ouibounce without passing an element
    // https://github.com/carlsednaoui/ouibounce/issues/30
    function fire() {
      if (isDisabled()) {
        return
      }
  
      if (typeof options.htmlElement !== 'undefined') {
        options.htmlElement.style.display = 'block'
      }
  
      callback()
      disable()
    }
  
    function disable(custom_options?: OuiBounceConfig) {
      const options = custom_options || {}
  
      // you can pass a specific cookie expiration when using the OuiBounce API
      // ex: _ouiBounce.disable({ cookieExpire: 5 });
      if (typeof options.cookieExpire !== 'undefined') {
        cookieExpire = setDefaultCookieExpire(options.cookieExpire) || ''
      }
  
      // you can pass use sitewide cookies too
      // ex: _ouiBounce.disable({ cookieExpire: 5, sitewide: true });
      if (typeof options.sitewide !== 'undefined' && options.sitewide === true) {
        sitewide = ';path=/'
      }
  
      // you can pass a domain string when the cookie should be read subdomain-wise
      // ex: _ouiBounce.disable({ cookieDomain: '.example.com' });
      if (typeof options.cookieDomain !== 'undefined') {
        cookieDomain = ';domain=' + options.cookieDomain
      }
  
      if (typeof options.cookieName !== 'undefined') {
        cookieName = options.cookieName
      }
  
      document.cookie = cookieName + '=true' + cookieExpire + cookieDomain + sitewide
  
      // remove listeners
      _html.removeEventListener('mouseleave', handleMouseleave)
      _html.removeEventListener('mouseenter', handleMouseenter)
      _html.removeEventListener('keydown', handleKeydown)
    }
  
    return {
      fire: fire,
      disable: disable,
      isDisabled: isDisabled
    }
  }
  
  export interface OuiBounceConfig {
    aggressive?: boolean
    sensitivity?: number
    timer?: number
    delay?: number
    callback?: () => void
    cookieExpire?: number
    sitewide?: boolean
    cookieDomain?: string
    cookieName?: string
  }
