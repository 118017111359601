<i18n lang="json">
{
  "en": {
    "searchAll": "Search all",
    "exploreCities": "Explore cities",
    "campgrounds": "Campgrounds",
    "logoAlt": "RVezy Campgrounds",
    "menu": "Menu"
  },
  "fr": {
    "searchAll": "Rechercher tout",
    "exploreCities": "Explorer les villes",
    "campgrounds": "Campings",
    "logoAlt": "RVezy Campings",
    "menu": "Menu"
  }
}
</i18n>

<template>
  <div
    ref="headerEl"
    class="top-0 z-30 flex-grow-0 flex-shrink-0 py-3"
    :class="[
      isHeaderTransparent ? 'bg-transparent' : 'bg-white',
      {
        sticky
      }
    ]"
  >
    <nav class="relative flex flex-wrap content-between items-center px-4 z-10 w-full">
      <div class="flex flex-1 gap-4 items-center">
        <NuxtLink
          :to="`/${locale}`"
          external
          :title="t('logoAlt')"
          :class="['border-r', isHeaderTransparent ? 'border-white' : 'border-primary-100']"
        >
          <LogoRvezy class="block mr-4 w-[3.85rem] lg:w-[5.5rem]" :class="{ 'text-white': isHeaderTransparent }" />
        </NuxtLink>
        <div
          :class="[isLargeScreen ? 'subtitle-0' : 'caption', isHeaderTransparent ? 'text-white' : 'text-primary-350']"
        >
          {{ t('campgrounds') }}
        </div>
        <div v-if="isLargeScreen && showSearchForm">
          <div class="bg-highlight-50 rounded-full px-2 w-96 max-w-full border-primary border">
            <PlaceSearch />
          </div>
        </div>
      </div>

      <button
        type="button"
        class="bg-transparent py-1 px-3 border-none w-11 text-primary-500 lg:hidden"
        @click="handleMenuToggle"
        :aria-label="t('menu')"
      >
        <div :class="['body-2', { 'text-white': isHeaderTransparent }]">
          <IconClose v-if="menuOpened" class="h-6" />
          <IconHamburger v-else :class="['h-6']" />
        </div>
      </button>

      <div
        ref="menuContainer"
        :class="[
          'flex leading-6 items-stretch overflow-y-scroll basis-full flex-col border-b-primary-100 mt-4',
          isHeaderTransparent ? 'text-white' : 'text-primary',
          menuOpened ? 'border-b max-h-dvh' : 'max-h-0',
          {
            'duration-500 transition-[max-height border]': !menuSelfClosing
          },
          'lg:mt-0 lg:gap-4 lg:max-h-none lg:flex-initial lg:flex-row lg:overflow-visible lg:items-center'
        ]"
      >
        <MenuSubMenu
          name="ExploreCities"
          :class="[
            'outline-none cursor-pointer caption no-underline font-semibold gap-4 py-4 px-5 border-b border-b-highlight-100',
            'last:border-b-0',
            'lg:inline lg:p-0 lg:border-b-0',
            isHeaderTransparent ? 'text-white' : 'text-primary'
          ]"
          button-css-classes="font-semibold cursor-pointer"
          :items="exploreCitiesItems"
        >
          {{ t('exploreCities') }}
        </MenuSubMenu>

        <AppLangSwitcher
          class="flex cursor-pointer caption no-underline items-center font-semibold gap-4 py-4 px-5 border-b-highlight-100 last:border-b-0 lg:inline lg:p-0 lg:border-b-0 lg:hover:text-highlight uppercase"
        />
      </div>
    </nav>

    <div v-if="!isLargeScreen && showSearchForm">
      <div class="bg-highlight-50 w-full py-1 pr-4 border-primary border-y">
        <PlaceSearch />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useResizeObserver, useWindowScroll } from '@vueuse/core'
import type { CountryCode, LanguageCode, Place } from '~/types/api'
import type { MenuItem } from '~/types/components/menu-item'

const props = withDefaults(
  defineProps<{
    sticky?: boolean
    showSearchForm?: boolean
    transparentHeader?: boolean
  }>(),
  {
    showSearchForm: true
  }
)

const { locale, t } = useI18n({ useScope: 'local' })
const isLargeScreen = useIsLargeScreen()
const geolocation = useGeolocation()
const $config = useRuntimeConfig()
const { logCustomEvent } = await useBraze()

const { y: windowY } = useWindowScroll()

const exploreCitiesItems = computed(() => {
  return [
    nearbyCities.value?.map((place) => {
      return {
        name: place.placeName,
        href: getLocalePath({ name: 'place', params: { place: place.route } })
      }
    }) as MenuItem[],
    nearbyCountries.value
      ?.map((place) => {
        return {
          name: place.name,
          href: getLocalePath({
            name: 'place',
            params: { place: place.route }
          })
        }
      })
      .concat([{ name: t('searchAll'), href: getLocalePath({ name: 'search' }) }]) as MenuItem[]
  ]
})

const menuOpened = ref(false)
const menuSelfClosing = ref(false)
const headerEl = ref<HTMLElement>()
const bodyScroll = useBodyScroll()
const route = useRoute()
const menuContainer = ref<InstanceType<typeof HTMLElement>>()

const isHeaderTransparent = computed(
  () =>
    props.transparentHeader &&
    ((windowY.value < 100 && isLargeScreen.value) || (windowY.value === 0 && !isLargeScreen.value)) &&
    !menuOpened.value
)

const {
  data: nearbyCities,
  error: nearbyCitiesError,
  refresh: refreshNearbyCities
} = await useAsyncData('nearby-cities', async () => {
  const { data, error } = await campgroundApiClient().GET('/Place/Search', {
    params: {
      query: {
        PlaceType: 'City',
        PageSize: 10,
        Latitude: geolocation.latitude,
        Longitude: geolocation.longitude,
        DistanceKm: 100000,
        Country: geolocation.country as CountryCode,
        LanguageCode: locale.value as LanguageCode
      }
    }
  })

  if (error) captureError(toRaw(error))

  return data.results as Place[]
})

const {
  data: nearbyCountries,
  error: nearbyCountriesError,
  refresh: refreshNearbyCountries
} = await useAsyncData('nearby-countries', async () => {
  const { data, error } = await campgroundApiClient().GET('/Place/Search', {
    params: {
      query: {
        PlaceType: 'Country',
        PageSize: 2,
        Latitude: geolocation.latitude,
        Longitude: geolocation.longitude,
        DistanceKm: 100000,
        LanguageCode: locale.value as LanguageCode
      }
    }
  })

  if (error) captureError(toRaw(error))

  return data?.results as Place[]
})

const handleMenuToggle = () => {
  menuOpened.value = !menuOpened.value
  bodyScroll.value = !menuOpened.value
}

const setAppHeaderHeight = () => {
  if (!document || !headerEl.value) return

  document.documentElement.style.setProperty('--app-header-height', `${headerEl.value.offsetHeight - 1}px`)
}

onMounted(() => {
  windowY.value = window.scrollY
  exitIntent(false, <string>$config.public.segment.appName, logCustomEvent)
  setAppHeaderHeight()

  useResizeObserver(headerEl.value, () => {
    setAppHeaderHeight()
  })
})

watch(
  () => locale.value,
  () => {
    refreshNearbyCities()
    refreshNearbyCountries()
  }
)

/**
 * since the route is changing we'll just prevent the animation
 * from happening and just close the menu immediately
 */
watch(
  () => route.fullPath,
  () => {
    if (menuOpened.value) {
      menuSelfClosing.value = true
      setTimeout(() => {
        handleMenuToggle()
        setTimeout(() => {
          menuSelfClosing.value = false
        }, 100)
      }, 0)
    }
  }
)

watch(
  () => isLargeScreen.value,
  () => setAppHeaderHeight
)

watch(
  () => nearbyCitiesError.value,
  () => captureError(toRaw(nearbyCitiesError.value) ?? new Error('Error fetching nearby cities!'))
)

watch(
  () => nearbyCountriesError.value,
  () => captureError(toRaw(nearbyCountriesError.value) ?? new Error('Error fetching nearby countries!'))
)
</script>
