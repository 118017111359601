<template>
  <NuxtLink :to="switchLocalePath(otherLocale.code)">
    {{ otherLocale.code }}
  </NuxtLink>
</template>

<script setup>
const { locale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const otherLocale = computed(() => {
  return locales.value.find((i) => i.code !== locale.value)
})
</script>
