<i18n lang="json">
{
  "en": {
    "terms": "Terms",
    "privacy": "Privacy",
    "copyright": "© {year} RVezy. All rights reserved."
  },
  "fr": {
    "terms": "Termes",
    "privacy": "Confidentialité",
    "copyright": "© {year} RVezy. Tous droits réservés."
  }
}
</i18n>

<template>
  <footer class="flex-grow-0 flex-shrink-0 bg-primary text-white p-4 caption">
    <div class="flex justify-between items-center">
      <div>{{ t('copyright', { year: new Date().getUTCFullYear() }) }}</div>
      <div class="flex gap-1">
        <NuxtLink :to="getLocalePath('/c/terms-and-conditions')">{{ t('terms') }}</NuxtLink>
        <span>|</span>
        <NuxtLink :to="getLocalePath('/c/privacy-policy')">{{ t('privacy') }}</NuxtLink>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
</script>
